import { defineStore } from "pinia";

declare interface CampaignState {
  responded: boolean;
  nextTry: number | boolean;
}

export const useCampaignStore = defineStore("campaign", {
  state: (): CampaignState => {
    return {
      responded: false,
      nextTry: new Date().getTime(),
    };
  },
  getters: {
    nextTryDate(state) {
      return state.nextTry !== false && typeof state.nextTry === "number"
        ? new Date(state.nextTry)
        : false;
    },
    shouldPrompt(state): boolean {
      if (this.nextTryDate === false) {
        return false;
      }
      const now = new Date();
      return now > this.nextTryDate && !state.responded;
    },
  },
  actions: {
    closeCampaign() {
      const tryDate = new Date();
      this.nextTry = tryDate.setDate(tryDate.getDate() + 7);
      localStorage.setItem("campaign", JSON.stringify(this.$state));
    },
    campaignSignUp(data: { email: string }) {
      setTimeout(() => {
        this.$state = {
          responded: true,
          nextTry: false,
        };
      }, 3000);
      return $fetch("/sign-up", {
        method: "POST",
        body: { email: data.email },
      });
    },
    setCampaign(campaign: CampaignState | null) {
      if (campaign) {
        this.$state = campaign;
      }
    },
  },
});
